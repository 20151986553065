import Img1 from './assets/img/Sherman_images/Sherman_01.jpg'
import Img2 from './assets/img/Sherman_images/Sherman_02.jpg'
import Img3 from './assets/img/Sherman_images/Sherman_03.jpg'
import Img4 from './assets/img/Sherman_images/Sherman_04.jpg'
import Img5 from './assets/img/Sherman_images/Sherman_05.jpg'
import Img6 from './assets/img/Sherman_images/Sherman_06.jpg'
import Img7 from './assets/img/Sherman_images/Sherman_07.jpg'
import Img8 from './assets/img/Sherman_images/Sherman_08.jpg'
import Img9 from './assets/img/Sherman_images/Sherman_09.jpg'
import Img10 from './assets/img/Sherman_images/Sherman_10.jpg'
import Img11 from './assets/img/Sherman_images/Sherman_11.jpg'


export default [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11
]
