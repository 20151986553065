import Img1 from './assets/img/drawings/drawing_01.jpg'
import Img2 from './assets/img/drawings/drawing_02.jpg'
import Img3 from './assets/img/drawings/drawing_03.jpg'
import Img4 from './assets/img/drawings/drawing_04.jpg'
import Img5 from './assets/img/drawings/drawing_05.jpg'
import Img6 from './assets/img/drawings/drawing_06.jpg'



export default [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6
]
